import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"


import css from "@css/components/blocks/index/Download.module.styl"


export default function Download(){
    const { t } = useTranslation()
    const q = useStaticQuery(graphql`
        query {
            img01 : file(relativePath: { eq: "IndexSupport__img__01@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 244) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            img02 : file(relativePath: { eq: "IndexSupport__img__02@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    return (
        <div className={css.container}>
            <div className={css.inner}>
                <section className={css.sct}>
                    <h3 className={css.title}>
                        <span className={css.l1}>{t('index__supportTitleDownload')}</span>
                        <span className={css.l2} dangerouslySetInnerHTML={{ __html: t('index__supportTitle2') }} />
                    </h3>
                    <div className={css.cards}>
                        <div className={css.card}>
                            <figure className={css.thumbnail2}>
                                <div className={css.inner}>
                                    {/* <Image
                                        fluid={img01.childImageSharp.fluid}
                                        objectFit="contain"
                                        objectPosition="50% 50%"
                                    /> */}
                                    <FluidImage data={q.img01} />
                                </div>
                            </figure>
                            <div className={css.detail}>
                                <h4 className={css.title}>{t('index__supportDetailTitle1')}</h4>
                                <p className={css.text}>
                                    {t('index__supportDetailText1')}
                                </p>
                                <div className={css.pc}>
                                    <p className={css.buttons}>
                                        <Link className={css.button_download} to="/download">{t('index__supportDetailButton1_1')}</Link>
                                        <Link className={css.button_gideline} to="/download" scrollTarget="#gideline">{t('index__supportDetailButton1_2')}</Link>
                                    </p>
                                </div>
                            </div>
                            <div className={css.sp}>
                                <p className={css.buttons}>
                                    <Link className={css.button_download} to="/download">{t('index__supportDetailButton1_1')}</Link>
                                    <Link className={css.button_gideline} to="/download" scrollTarget="#gideline">{t('index__supportDetailButton1_2')}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}