import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/Seo"
import Hero from "@components/blocks/index/Hero"
import News from "@components/blocks/index/News"
import Support from "@components/blocks/index/Support"
import OfficalWorks from "@components/blocks/index/OfficialWorks"
import CaseStudy from "@components/blocks/index/CaseStudy"
import Contact from "@components/blocks/index/Contact"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Anniversary from "@components/blocks/index/Anniversary/"

import css from "@css/components/blocks/index/Index.module.styl"

export default function Index(props)
{

	React.useEffect(()=>{
	})

	return (
		<React.Fragment>
			<SEO pathname="/" />
			<main className={css.container} id="main">
				<Hero/>
				{/* <Anniversary /> */}
				<News/>
				<div className={css.wraper}>
					<div className={css.orangeBg}/>
					<Support/>
					<OfficalWorks/>
				</div>
				<CaseStudy/>
				<div className={css.wraper} id="contact">
					<div className={css.greenBg}/>
					<Contact/>
				</div>
				<FooterSns/>
			</main>
			<Footer/>
		</React.Fragment>
	)
}
