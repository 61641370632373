import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"


import css from "@css/components/blocks/hotItems/ContentBox.module.styl"


export default function ContentBox({contentMode})
{
    const { t } = useTranslation()
    const q = useStaticQuery(graphql`
        query {
            shopImg : file(relativePath: { eq: "IndexSupport__img__shop@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 349) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    return (
        <div className={css.container}>
            <div className={css.inner}>
                <section className={css.sct}>
                    <h3 className={css.title}>
                        <span className={css.l1}>{t('index__supportTitleShop__title1')}</span>
                        <span className={css.l2} dangerouslySetInnerHTML={{ __html: t('index__supportTitleShop__title2') }} />
                    </h3>
                    <div className={css.card}>
                        <figure className={css.thumbnail2}>
                            <div className={css.inner}>
                                <FluidImage data={q.shopImg} />
                            </div>
                        </figure>
                        <div className={css.detail}>
                            <h4 className={css.title} dangerouslySetInnerHTML={{ __html: t('index__supportTitleShop__text1') }}></h4>
                            <p className={css.text} dangerouslySetInnerHTML={{__html: t('index__supportTitleShop__text2') }}>
                            </p>
                            <div className={css.pc}>
                                <p className={css.buttons}>
                                    <Link className={css.button} to={urls.shop} target="_blank" isHtml={true}>{t('index__supportTitleShop__button1')}</Link>
                                    <Link className={css.button} to="/linestickers/" target="_blank" isHtml={true}>{t('index__supportTitleShop__button2')}</Link>
                                </p>
                            </div>
                        </div>
                        <div className={css.sp}>
                            <p className={css.buttons}>
                                <Link className={css.button} to={urls.shop} target="_blank" isHtml={true}>{t('index__supportTitleShop__button1')}</Link>
                                <Link className={css.button} to="/linestickers/" target="_blank" isHtml={true}>{t('index__supportTitleShop__button2')}</Link>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}