import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import {useTranslation} from 'react-i18next'

import Link from "@components/Link"
import NewsList from "@components/blocks/news/List"

import css from "@css/components/blocks/index/CaseStudy.module.styl"

export default function IndexCaseStudy()
{
    const {t} = useTranslation()
    const { allWordpressPost } = useStaticQuery(graphql`
        query IndexCaseStudyQuery {
            allWordpressPost(
                sort: { fields: date, order: DESC }
                limit: 3
                skip: 0
                filter: {categories: {elemMatch: {name: {eq: "CASE STUDY"}}}}
            ) {
                edges {
                    node {
                        id
                        slug
                        status
                        content
                        date(formatString: "MMMM DD, YYYY")
                        title
                        path
                        featured_media {
                            source_url
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 640) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        categories {
                            id
                            name
                            path
                            slug
                        }
                    }
                }
            }
        }
    `)

    const newsData = allWordpressPost.edges

    return(
        <div className={css.container}>
            <div className={css.inner}>
                <h3 className={css.title}>
                    <span className={css.l1}>{ t('index__caseStudyTitle1') }</span>
                    <span className={css.l2}>{ t('index__caseStudyTitle2') }</span>
                </h3>
                <div className={css.list}>
                    <NewsList data={newsData}/>
                </div>
            </div>
            <Link to='/news/category/case-study/' className={css.button}>{ t('index__caseStudyMoreButton') }</Link>
        </div>
    )
}
