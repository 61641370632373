import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import { Svg } from "@components/Svg"
import {useTranslation} from 'react-i18next'

import css from "@css/components/blocks/index/Hero.module.styl"
// import LogoSvg from "@images/Global__logo__miraicomachi.svg"

export default function IndexHero()
{
    const {t} = useTranslation()

    const q = useStaticQuery(graphql`
        query HeroQuery {
            logo : file(relativePath: { eq: "Global__logo__miraicomachi.svg" }) { publicURL }
            miraicomachi : file(relativePath: { eq: "IndexHero__img__miraicomachi-3@2x.png" }) { childImageSharp { fluid(maxWidth: 1280) { ...GatsbyImageSharpFluid } } publicURL }
        }
    `)

    return(
            <section className={css.container} id="about">
                <figure className={css.miraicomachi}>
                    <div className={css.inner1}>
                        <div className={css.inner2}>
                            <FluidImage data={q.miraicomachi} loading="eager"/>
                            {/* <img src={q.miraicomachi.publicURL} alt=""/> */}
                        </div>
                    </div>
                </figure>
                <div className={css.content}>
                    <div className={css.inner}>
                        <p className={css.message} dangerouslySetInnerHTML={{__html: t('index__heroMessage')}}>
                        </p>
                        <h1 className={css.logo}>
                            {/* <LogoSvg alt="ミライ小町 - MIRAI KOMACHI -"/> */}
                            <Svg src={q.logo.publicURL} title="ミライ小町 - MIRAI KOMACHI -"/>
                            {/*<Image fluid={logo.childImageSharp.fluid} alt="ミライ小町 MIRAI KOMACHI"/>*/}
                        </h1>
                        <div className={css.pc}>
                            <h2 className={css.text} dangerouslySetInnerHTML={{__html: t('index__heroText1')}}/>
                        </div>
                    </div>
                    <div className={css.scrollGide}>
                        <span className={css.text}>SCROLL</span>
                        <span className={css.arrow}></span>
                    </div>
                </div>
                <div className={css.sp}>
                    <div className={css.sp}>
                        <h2 className={css.text} dangerouslySetInnerHTML={{__html: t('index__heroText1')}}/>
                    </div>
                </div>
            </section>
        )
}
