import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"


import css from "@css/components/blocks/index/Vocaloid.module.styl"


export default function Vocaloid({contentMode})
{
    const { t } = useTranslation()
    const q = useStaticQuery(graphql`
        query {
            img01 : file(relativePath: { eq: "IndexSupport__img__01@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 244) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            img02 : file(relativePath: { eq: "IndexSupport__img__02@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    return (
        <>
        {!contentMode ?
                <div className={css.container}>
                    <div className={css.inner}>
                        <section className={css.sct}>
                            <h3 className={css.title}>
                                <span className={css.l1}>{t('index__supportTitleVocaloid')}</span>
                                <span className={css.l2} dangerouslySetInnerHTML={{ __html: t('index__supportTitle1') }} />
                            </h3>
                            <Card q={q}/>
                        </section>
                    </div>
                </div>
            :
                <Card q={q} />
        }
        </>
    )
}

function Card({q}){
    const { t } = useTranslation()
    const urls = useURL()

    return(
        <div className={css.card}>
            <figure className={css.thumbnail2}>
                <div className={css.inner}>
                    {/* <Image
                                        fluid={img02.childImageSharp.fluid}
                                        objectFit="contain"
                                        objectPosition="50% 50%"
                                    /> */}
                    <FluidImage data={q.img02} />
                </div>
            </figure>
            <div className={css.detail}>
                <h4 className={css.title} dangerouslySetInnerHTML={{ __html: t('index__supportDetailTitle2') }}></h4>
                <p className={css.text}>
                    {t('index__supportDetailText2')}
                </p>
                <div className={css.pc}>
                    <p className={css.buttons}>
                        <Link className={css.button_shop} to={urls.store} target="_blank" isHtml={true}>{t('index__supportDetailButton2_1')}</Link>
                    </p>
                </div>
            </div>
            <div className={css.sp}>
                <p className={css.buttons}>
                    <Link className={css.button_shop} to={urls.store} target="_blank" isHtml={true}>{t('index__supportDetailButton2_1')}</Link>
                </p>
            </div>
        </div>
    )
}