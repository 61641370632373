import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'

import Link from "@components/Link"

import css from "@css/components/blocks/index/OfficialWorks.module.styl"

export default function IndexOfficialWorks({contentMode=false})
{
    const {t} = useTranslation()

    const q = useStaticQuery(graphql`
        query {
            illust : file(relativePath: { eq: "IndexOfficialWorks__img__illust@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
            comic : file(relativePath: { eq: "IndexOfficialWorks__img__comic@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
            songs : file(relativePath: { eq: "IndexOfficialWorks__img__songs@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
            shop : file(relativePath: { eq: "IndexOfficialWorks__img__online-shop@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
            sticker : file(relativePath: { eq: "IndexOfficialWorks__img__line-sticker@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
            labo : file(relativePath: { eq: "IndexOfficialWorks__img__labo@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) { ...GatsbyImageSharpFluid } } }
        }
    `)
    const data = [
        {
            title: 'ILLUST',
            sub: t('index__officialWorksItemText1'),
            to: '/illust/',
            target: null,
            img: q.illust,
        },{
            title: 'COMICS',
            sub: t('index__officialWorksItemText2'),
            to: '/comics/',
            target: null,
            img: q.comic,
        },
        {
            title: 'SONGS',
            sub: t('index__officialWorksItemText3'),
            to: '/songs/',
            target: null,
            img: q.songs,
        },
        // {
        //     title: 'LABO',
        //     sub: t('index__officialWorksItemText4'),
        //     to: '/labo/',
        //     target: null,
        //     img: q.labo,
        // },
        // {
        //     title: 'ONLINE SHOP',
        //     sub: t('index__officialWorksItemText4'),
        //     to: 'https://mangahack.myshopify.com/?sc_cid=txt_tw_20211021',
        //     target: '_blank',
        //     img: q.shop,
        // },
        // {
        //     title: 'LINE STICKERS',
        //     sub: t('index__officialWorksItemText5'),
        //     to: '/linestickers/',
        //     target: null,
        //     img: q.sticker
        // },
    ]
    return(
        <>
            { !contentMode ?
                <section className={css.container}>
                    <div className={css.inner}>
                        <h3 className={css.title}>
                            <span className={css.l1}>{t('index__supportTitleOfficialWorks')}</span>
                            <span className={css.l2}>{t('index__officialWorksTitle2')}</span>
                        </h3>
                        <List data={data} />
                    </div>
                </section>
                :
                <List data={data} />
            }
        </>
    )
}


function List({data}){
    return(
        <ul className={css.list}>
            {
                data.map(({ title, sub, to, target, img, comingsoon }, i) => {
                    return (
                        <li className={css.item} key={i}>
                            { !comingsoon ?
                                <Link to={to} target={target}>
                                    <figure className={css.thumbnail}>
                                        {/* <Image
                                                    fluid={img.childImageSharp.fluid}
                                                    objectFit="cover"
                                                    objectPosition="50% 50%"
                                                /> */}
                                        <FluidImage data={img} />
                                    </figure>
                                    <div className={css.category}>
                                        <p className={css.title}>{title}</p>
                                        <p className={css.sub}>{sub}</p>
                                    </div>
                                </Link>
                                :
                                <div className={css.comingsoon}>
                                    <figure className={css.thumbnail}>
                                        {/* <Image
                                                    fluid={img.childImageSharp.fluid}
                                                    objectFit="cover"
                                                    objectPosition="50% 50%"
                                                /> */}
                                        <FluidImage data={img} />
                                    </figure>
                                    <div className={css.category}>
                                        <p className={css.title}>{title}</p>
                                        <p className={css.sub}>{sub}</p>
                                    </div>
                                </div>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}