import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'
import Vocaloid from '@components/blocks/index/Vocaloid'
import Download from '@components/blocks/index/Download'
import Shop from '@components/blocks/index/Shop'
// import Link from "@components/Link"


import css from "@css/components/blocks/index/Support.module.styl"

export default function IndexSupport()
{
    // const {t} = useTranslation()
    //test
    const q = useStaticQuery(graphql`
        query IndexSupport {
            img01 : file(relativePath: { eq: "IndexSupport__img__01@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 244) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            img02 : file(relativePath: { eq: "IndexSupport__img__02@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    return(
        <section className={css.container}>
            <div className={css.inner}>
                <Vocaloid/>
                <Download/>
                <Shop/>
            </div>
        </section>
    )
}