import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import {useTranslation} from 'react-i18next'

import Link from "@components/Link"
import NewsList from "@components/blocks/news/List"

import css from "@css/components/blocks/index/News.module.styl"

export default function IndexNews()
{
    const {t} = useTranslation()
    //test
    // const { thumbnail } = useStaticQuery(graphql`
    //     query IndexNewsQuery {
    //         thumbnail : file(relativePath: { eq: "sample__img__thumbnail01@2x.png" }) {
    //             childImageSharp {
    //                 fluid(maxWidth: 343) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `)
    // const testData = [
    //     {
    //         title: 'SIGGRAPH Asia 2018 にてデモンストレーション出演いたしました',
    //         date: '2020.01.01',
    //         category: 'EVENT',
    //         to: '#',
    //         thumbnail: thumbnail,
    //     },{
    //         title: 'SIGGRAPH Asia 2018 にてデモンストレーション出演いたしました',
    //         date: '2020.01.01',
    //         category: 'EVENT',
    //         to: '#',
    //         thumbnail: thumbnail,
    //     },{
    //         title: 'SIGGRAPH Asia 2018 にてデモンストレーション出演いたしました',
    //         date: '2020.01.01',
    //         category: 'EVENT',
    //         to: '#',
    //         thumbnail: thumbnail,
    //     }
    // ]

    const { allWordpressPost } = useStaticQuery(graphql`
          query IndexNewsQuery {
            allWordpressPost(
                sort: { fields: date, order: DESC }
                limit: 3
                skip: 0
            ) {
                edges {
                    node {
                        id
                        slug
                        status
                        content
                        date(formatString: "YYYY-MM-DD")
                        title
                        path
                        wordpress_id
                        featured_media {
                            source_url
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 640) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        categories {
                            id
                            name
                            path
                            slug
                        }
                    }
                }
            }
        }
    `)
    const newsData = allWordpressPost.edges
    return(
        <section className={css.container} id="news">
            <div className={css.inner}>
                <h3 className={css.title}>
                    <span className={css.l1}>{t('index__newsTitle1')}</span>
                    <span className={css.l2} dangerouslySetInnerHTML={{__html: t('index__newsTitle2')}}/>
                </h3>
                <div className={css.list}>
                    <NewsList data={newsData}/>
                </div>
            </div>
            <Link to='/news' className={css.button}>{t('index__newsMoreButton')}</Link>
        </section>
    )
}
