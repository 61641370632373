import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
// import Link from "@components/Link"

import Mailto from '@components/blocks/global/ContactMailto'

import css from "@css/components/blocks/index/Contact.module.styl"

export default function IndexContact()
{
    const {t} = useTranslation()
    const q = useStaticQuery(graphql`
        query IndexContactQuery {
            mail : file(relativePath: { eq: "IndexContact__icn__mail@2x.png" }) { childImageSharp { fluid(maxWidth: 160) { ...GatsbyImageSharpFluid } } }
        }
    `)

    return(
        <div className={css.container}>
            <div className={css.inner}>
                <h3 className={css.title}>
                    <span className={css.l1}>{ t('index__contactTitle1') }</span>
                    <span className={css.l2}>{ t('index__contactTitle2') }</span>
                </h3>
                <p className={css.text} dangerouslySetInnerHTML={ {__html: t('index__contactText1')} } />
                <Mailto className={css.button} obfuscate={true}>
                    <span className={css.icon}>
                        <FluidImage data={q.mail}/>
                    </span>
                    <span>CONTACT</span>
                </Mailto>
            </div>
        </div>
     )
}
