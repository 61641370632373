import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"

import css from "@css/components/blocks/common/List.module.styl"

export default function NewsList({data, thumbnail_brank})
{
    return(
        <React.Fragment>
        { data.length !== 0 ?
        <ul className={css.container}>
            {
                data.map(( {node}, i)=>{
                    if( !node ){
                        return <div/>
                    }
                    if( node.status !== "publish"){
                        return
                    }

                    let { title, date, cat_news, categories, featured_media, path, wordpress_id  } = node
                    let to = `${path}`

                    let categoryDom = []
                    categories && categories.map( ({name,path,slug},i) => {
                        // console.log(name)
                        // categoryDom.push(<span className={css.sep}>/</span>)
                        categoryDom.push(
                            <span key={i}>{name}</span>
                        )
                        categoryDom.push(
                            <br key={`br${i}`}/>
                        )
                    })
                    return(
                        <li className={css.item} key={i}>
                            <Link to={`/news${to}`}>
                                <figure className={css.thumbnail}>
                                    { featured_media ?
                                        <FluidImage data={featured_media.localFile}/>
                                        :
                                        thumbnail_brank && <FluidImage data={thumbnail_brank}/>
                                    }
                                </figure>
                                <div className={css.cat}>
                                    {categoryDom}
                                </div>
                                <div className={css.detail}>
                                    <p className={css.title}>{title}</p>
                                    <div className={css.info}>
                                        <p className={css.date}>
                                            <span>{date}</span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )
                })
            }
        </ul>
        :
        <ul className={css.container}>
            <li className={css.nopost}>投稿はまだありません。</li>
        </ul>
        }
        </React.Fragment>
    )
}
