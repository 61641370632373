import React from "react"
import Mailto from '@components/Mailto'
import { useStores } from "@hooks/useStores"

export default function GlobalContactMailto({children, className, obfuscate = false})
{
	const { appStore } = useStores()
	return(
		<Mailto
			className={className}
			obfuscate={obfuscate}
			email='miraikomachi@bandainamcostudios.co.jp'
			headers={
				{
					cc: '',
					bcc: '',
					subject:`【お問い合わせ】`,
					body: `
				`}
		}>
			{children}
		</Mailto>
	)
}